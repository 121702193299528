import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import RestClient from "../apiClient/apiClient";
import { useLocalStorage } from "./useLocalStorage";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [ngos, setNgos] = useState();
  const [userToken, setUserToken] = useLocalStorage("authToken")
  const navigate = useNavigate();

    
  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data);
    navigate("/");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    setUserToken(null)
    navigate("/", { replace: true });
  };

  useEffect(() => {
    if(userToken && !user)
        RestClient.get('https://api.donacode.com/api/v1/users').then((response) => {
            setUser(response.result.data.user);
            setNgos(response.result.data.ngos);
        })
        .catch((err) => {
            console.log("Error!", err);
            logout()
        })
    }, [user, userToken]);

  const value = useMemo(
    () => ({
      user,
      ngos,
      setNgos,
      userToken,
      setUserToken,
      login,
      logout
    }),
    [user, userToken]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};