import React, { useState } from "react";
import { Input } from "antd";
import styles from './style.module.scss';

const FormItem = ({ name, required, label, value, placeholder, disabled, onChange, style, extraInputProps, labelStyle={}, type, error, disableHeader, inputStyle }) => {

    return (
        <div className={styles.formItem} style={{...style}}>
            {!disableHeader && <label for={name} className={styles.label} style={{...labelStyle}}>{label}{required && <span style={{color: "red"}}>*</span>}</label>}
            
            <Input 
                type={type}
                name={name} 
                onChange={onChange} 
                value={value} 
                placeholder={placeholder}
                disabled={disabled}
                style={{ background: disabled ? "#F9FAFB" : "white", borderRadius: "8px", border: `1px solid ${error? 'red' :'#D0D5DD'}`, padding: "10px 14px", ...inputStyle }} 
                {...extraInputProps}
            />
        </div>
    )
};

export default FormItem;