import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Table } from "antd";
import RestClient from "../../apiClient/apiClient";
import Navbar from "../../components/navbar/navbar";
import styles from './style.module.scss';
import { ReactComponent as SparkIcon } from "../../assets/spark.svg";
import { ReactComponent as InstagramIcon } from "../../assets/instagram_icon.svg";
import { useEffect } from "react";
import { openNotification } from "../../utils";
import { useAuth } from "../../hooks/useAuth";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";

import {
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";

const ShareCode = () => {
    const [data, setData] = useState();
    const [dataError, setDataError] = useState(false);
    const { donacode } = useParams();
    const { setNgos } = useAuth();
    const navigate = useNavigate();

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Donation(INR)',
            dataIndex: 'amount',
            key: 'donation',
        },
        {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
        },
    ]

    useEffect(() => {
        if (!data && !dataError) {
            RestClient.get("https://api.donacode.com/api/v1/orders").then((res) => {
                if (res.ok) {
                    setData(res.result.map(({ name, note, email, amount }) => ({
                        name,
                        email,
                        amount,
                        note
                    })))
                }
                else {
                    openNotification("Something went wrong!", "Unable to fetch the data.", "error");
                    setDataError(true);
                }
            })
        }
    }, [data, dataError]);

    const handleEdit = () => {
        navigate('/create-code');
    }

    return (
        <div className={styles.shareCodePage}>
            <Navbar />

            <div className={styles.shareCodeSection} style={{ textAlign: "center", overflow: "hidden", flex: 1 }}>

                <div>
                    <SparkIcon />
                </div>
                <p className={styles.fontStyle1}>Your code is ready to share!</p>
                <p className={styles.fontStyle4}>Your friends and family can now use this link to gift a donation on your behalf.<br />Spread the word!</p>
                <div className={styles.donacodeCard}>
                    <p style={{ color: "#E31B54" }}><span style={{ color: "#98A2B3" }}>donacode.com/</span>{donacode}</p>
                    <Button onClick={() => { 
                        navigator.clipboard.writeText(`donacode.com/${donacode}`);
                        openNotification("Code Copied!", "You have copied the code to your clipboard.")
                 }} className={styles.firstBtn}>
                        Copy your code &amp; share it!
                    </Button>
                    <p style={{ color: "#98A2B3", display: "flex" }}>
                        <span style={{ margin: "auto 0" }}>or share it on:</span>
                        <FacebookShareButton url={`donacode.com/${donacode}`} quote="Donacode is a platform that allows you to create a donation code for your favourite NGO."> <FacebookIcon size={64} round={true} style={{ padding: "0 8px" }} /></FacebookShareButton>
                        <TwitterShareButton url={`donacode.com/${donacode}`} title="Donacode is a platform that allows you to create a donation code for your favourite NGO."> <TwitterIcon size={64} round={true} style={{ padding: "0 8px" }} /></TwitterShareButton>
                        <WhatsappShareButton url={`donacode.com/${donacode}`} title="Donacode is a platform that allows you to create a donation code for your favourite NGO."> <WhatsappIcon size={64} round={true} style={{ padding: "0 8px" }} /></WhatsappShareButton>
                        <LinkedinShareButton url={`donacode.com/${donacode}`} summary="Donacode is a platform that allows you to create a donation code for your favourite NGO."> <LinkedinIcon size={64} round={true} style={{ padding: "0 8px" }} /></LinkedinShareButton>
                        {/* <a href={`https://www.facebook.com/sharer/sharer.php?u=Donate%20through%20my%20Donacode:%20donacode.com/${donacode}`} style={{padding: "0 8px"}}><FBIcon /></a>
                        <a href={`https://twitter.com/intent/tweet?text=Donate%20through%20my%20Donacode:%20donacode.com/${donacode}`} style={{padding: "0 8px"}}><TwitterIcon /></a>
                        <a href={`http://instagram.com/ayush9398?description=Donate%20using%20donacode.com/${donacode}title="Donation"`} style={{padding: "0 8px"}}><InstagramIcon /></a>
                        <a href="" style={{padding: "0 8px"}}><LinkedinIcon /></a> */}
                    </p>
                    <p style={{ fontSize: "18px" }}>Edit the selected NGOs <Button type="link" onClick={handleEdit} style={{ fontSize: "18px", padding: 0 }}>here</Button></p>
                </div>
                <div style={{ margin: "20px" }}>
                    <Table columns={columns} dataSource={data} />
                </div>
            </div>
            <div className={styles.pageFooter}>
                <span className={styles.note}>© 2022 DonaCode.<br /> All rights reserved.</span>
                <a href="/about">About Us</a>
                <a href="/terms-and-conditions">Terms &amp; Conditions</a>
                <a href="/faqs">FAQs</a>
                <a href="/privacy">Privacy</a>
            </div>


        </div>
    )
}

export default ShareCode;