import React from "react";
import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import styles from './style.module.scss';
import logo from "../../assets/Donacode.svg";
import gift from "../../assets/gift.svg";
import { useAuth } from "../../hooks/useAuth";
import { openNotification } from "../../utils";


const Navbar = () => {
    const { user, ngos, logout } = useAuth();
    const navigate = useNavigate();

    return (
        <nav className={styles.navbar}>
            <div className={styles.title} style={{ cursor: "pointer" }} onClick={() => navigate("/")}><img className={styles.logo} src={logo} /></div>
            <div className={styles.right}>
                <Link to="/about">About Us</Link>
                <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
                {user ? <span onClick={() => {
                    logout();
                    openNotification("Logged out!", "You have logged out successfully!", "success")
                }}>Logout</span> : <Link to="/login">Login</Link>}
                <Button onClick={() => {
                    if (user && user.donacode && ngos && ngos.length)
                        return navigate(`/share-code/${user.donacode}`)
                    navigate("/create-code")
                }} className={styles.firstBtn}>
                    <img src={gift} style={{ margin: "-4px 8px 0 0" }} />
                    {user && ngos && ngos.length && user.donacode? "See your code" : "Make your own code"}
                </Button>
            </div>
        </nav>
    );
}

export default Navbar;