import React, { useState } from "react";
import { Button } from "antd";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import styles from "./style.module.scss";
import muditImg from "../../assets/mudit.png";
import gift from "../../assets/gift.svg";
import { useAuth } from "../../hooks/useAuth";
import { useEffect } from "react";

const About = () => {
    const [userDonacode, setUserDonacode] = useState();
    const { user, ngos } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        if (user && ngos && ngos.length && !userDonacode) {
            setUserDonacode(user.donacode);
        }
    }, [user, ngos]);

    return (
        <div>
            <Navbar userDonacode={userDonacode} />
            <div className={styles.section1}>
                <div>
                    <p className={styles.fontStyle1} style={{ fontSize: "36px" }}>Our Founder</p>
                    <p className={styles.fontStyle4}>
                        Donacode is the brainchild of Mudit Baid. He is a Grade 12 student at Dhirubhai Ambani
                        International School. He wants to encourage the culture of philanthropy in India.
                    </p>

                </div>
                <div>
                    <div style={{ display: "flex", justifyContent: "center", height: "270px", width: "270px", border: "1px solid black", borderRadius: "50%", margin: "auto", overflow: "hidden" }}>
                        <img src={muditImg}
                            style={{ height: "350px", width: "auto" }}
                        />
                    </div>
                </div>

            </div>
            
            <div className={styles.section2}>
                <div>
                    <p className={styles.fontStyle1} style={{ fontSize: "36px" }}>Our Inspiration</p>
                    <p className={styles.fontStyle4}>
                        I read an article some years back describing how Mr. Rishad Premji, Chairman of Wipro Ltd.,
                        requested all his guests to donate to NGOs on the occasion of his marriage in 2005 instead of giving
                        him gifts. The article stuck in my head.
                        I have never met him, but hope to meet someday.
                    </p>

                </div>
            </div>

            <div className={styles.section3}>
                <div>
                    <p className={styles.fontStyle1} style={{ fontSize: "36px" }}>Our Philosphy</p>
                    <p className={styles.fontStyle4}>
                        In India, it is a cultural norm to bring gifts to birthday parties, marriages, and other events. There is
                        no harm in them, but often they are bought because one has to carry something - there are no
                        emotions attached. The gifts received are mostly recycled or sit in storerooms for months.
                        How about we change this culture? Donacode is an attempt to change the gift culture with a
                        donation culture. It is a platform that lets you support causes, and your friends contribute towards
                        the causes instead of bringing you gifts. In return, they receive immense satisfaction and an 80G
                        certificate for the donation made. With your help, money can be reallocated from gifting to an
                        organisation that will greatly value your money. WIN-WIN for everyone.
                    </p>

                </div>
            </div>
            <div className={styles.section4}>
                <div style={{ margin: "60px 0" }}>
                    <p className={styles.fontStyle4} style={{ textAlign: "left" }}>
                        On a mission to make the world a better <br />place for everyone.
                    </p>
                    <div className={styles.footerLinks} style={{ display: "flex", gap: "24px", fontSize: "16px", color: "#667085" }}>
                        <Link to="/about">About Us</Link>
                        <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
                        <Link to="/#faqs">FAQs</Link>
                        <Link to="/privacy">Privacy</Link>
                    </div>
                </div>
                <hr color="#EAECF0" />
                <div>
                    <p style={{ textAlign: "left" }}>© 2022 DonaCode. All rights reserved.</p>
                </div>
            </div>
        </div>)
}

export default About;