import React, { useRef, useState } from "react";
import { Button, Carousel } from "antd";
import classnames from "classnames";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import FormItem from "../../components/formItem/formItem";
import styles from "./style.module.scss";
import people from "../../assets/people.svg";
import heart from "../../assets/heart.svg";
import share from "../../assets/share.svg";
import guard from "../../assets/guard.svg";
import gift from "../../assets/gift.svg";
import firework from "../../assets/firework.svg";
import circledplus from "../../assets/circledplus.svg";
import arrowleft from "../../assets/arrow_left_icon.png";
import pic1 from "../../assets/raj_kumar.jpeg";
import pic2 from "../../assets/mihir.jpeg";
import { useAuth } from "../../hooks/useAuth";
import { useEffect } from "react";

const Home = () => {
    const [currentCarouselPos, setCurrentCarouselPos] = useState(0);
    const [toggledIndices, setToggledIndices] = useState({});
    const [inputCode, setInputCode] = useState("");
    const [enterCodeState, setEnterCodeState] = useState(false);
    const [userDonacode, setUserDonacode] = useState();
    const carouselRef = useRef();
    const { user, ngos } = useAuth();

    const navigate = useNavigate();
    const carouselGoto = (screenIndex) => {
        carouselRef.current.goTo(screenIndex, false);
    }

    useEffect(() => {
        if (user && ngos && ngos.length && !userDonacode) {
            setUserDonacode(user.donacode);
        }
    }, [user, ngos]);

    return (
        <div>
            <Navbar userDonacode={userDonacode} />
            <div className={styles.section1}>
                <div className={styles.sectionCol}>
                    <p className={styles.fontStyle1}>On your next occasion, </p>
                    <p className={styles.fontStyle1}>accept all the gifts.</p>
                    <div className={styles.btnContainer}>
                        {enterCodeState ?
                            (<>
                                <img src={arrowleft} style={{ height: "20px", width: "20px", margin: "12px", cursor: "pointer" }} onClick={() => setEnterCodeState(false)} />
                                <FormItem
                                    name="code"
                                    placeholder="Enter code"
                                    label="Code"
                                    value={inputCode}
                                    disableHeader
                                    onChange={(e) => setInputCode(e.target.value)}
                                    style={{ flex: 1 }}
                                />

                                <Button className={styles.firstBtn} style={{ padding: "10px 14px" }} onClick={() => { navigate(`/${inputCode}`) }} disabled={!inputCode}>Head to payement</Button>


                            </>
                            )
                            : (
                                <>
                                    <Button onClick={() => {
                                        if (userDonacode)
                                            return navigate(`/share-code/${userDonacode}`)
                                        navigate("/create-code")
                                    }} className={styles.firstBtn}> <img src={gift} style={{ margin: "-4px 8px 0 0" }} />  {userDonacode ? "See your donacode" : "Make your own code"}</Button>
                                    <Button onClick={() => { setEnterCodeState(true) }} className={styles.secondBtn}> <img src={firework} style={{ margin: "-4px 8px 0 0" }} /> I already have a friend's code</Button>
                                </>
                            )}
                    </div>
                </div>
                <div className={styles.sectionCol}>
                    <img className={styles.pplImage} src={people} />
                </div>
            </div>
            <div className={styles.section2}>
                <div className={styles.gridItem}>
                    <div>

                        <p className={styles.fontStyle1} style={{ fontSize: "40px" }}>Donacode represents </p>
                        <p className={styles.fontStyle1} style={{ fontSize: "40px" }}>&quot;Donate using a code&quot;.</p>
                    </div>

                </div>

                <div className={styles.gridItem}>
                    <p className={styles.fontStyle3} style={{ fontSize: "36px", marginBottom: "12px", marginLeft: "60px" }}>How does it work?</p>
                    <p className={styles.pointer}>
                        <p className={styles.iconContainer}>
                            <span className={styles.icon}>
                                <img src={heart} style={{ height: "20px !important", width: "20px !important" }} />
                            </span>
                        </p>


                        <p className={styles.content}>
                            <p className={styles.heading}>
                                Select what causes/NGOs you’d like to support
                            </p>
                            <p className={styles.fontStyle4}>
                                As an organizer of an occasion like a birthday party, you can register on Donacode and select the causes/NGOs you like to support.
                                Your unique code will be generated for you.
                            </p>

                        </p>
                    </p>

                </div>

                <div className={styles.gridItem}>
                    <p className={styles.fontStyle4} style={{ maxWidth: "560px" }}>
                        <p>
                            We all want to earn money - it is important. However, there is an even better feeling to have - giving
                            away the money you have earned to change lives. This act of kindness is more than just a &quot;donation&quot;
                            - it is an ability to spread happiness to people who need it the most.
                        </p>
                    </p>
                </div>


                <div className={styles.gridItem}>
                    <p className={styles.pointer}>
                        <p className={styles.iconContainer}>
                            <span className={styles.icon}>
                                <img src={share} style={{ height: "20px !important", width: "20px !important" }} />
                            </span>
                        </p>
                        <p className={styles.content}>
                            <p className={styles.heading}>
                                Share your custom code with friends &amp; family
                            </p>
                            <p className={styles.fontStyle4}>
                                Your friend can come to Donacode, give your code, select an NGO (from the available list), give the amount to donate to the cause, and make the payment.
                                Once the payment is completed, they will receive the 80G certificate in their email addresses.
                            </p>
                        </p>
                    </p>

                </div>

            </div>
            <div className={styles.embedVideoContainer} style={{ height: "900px" }}>
                <div className={styles.embedVideo}>
                    <iframe width="770" height="432" src="https://www.youtube.com/embed/5LDw8qp7Tek" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p className={classnames(styles.content, styles.fontStyle4)}>
                    The idea of Donacode came from my personal experiences: events, birthdays, and marriages. Most
                    of the gifts received on these occasions were either items I already had or not useful (special
                    mention to the body deodorants I received. I really don’t think I needed so many)
                    <br />
                    <br />
                    I had no choice but to start a loop of re-gifting - not a great thing to do, right? I tried telling guests
                    not to bring gifts - No success. Our generous Indian mindset does not allow people to come to a
                    party without a gift!! The solution to this problem was DONACODE - donate with a code.
                    <br />
                    <br />
                    A website that lets you support causes / NGOs that are dear to your heart and your friends
                    contribute towards the causes instead of bringing you gifts. In return, your friends receive immense
                    satisfaction and an 80G certificate for the donation made. WIN-WIN for everyone.
                </p>
            </div>
            <div className={styles.section3}>
                <p>
                    <img src={guard} />
                </p>
                <p className={styles.heading}>
                    All donations via DonaCode are<br /> tax exempt under section 80G
                </p>
                <p style={{ fontFamily: "Inter" }}>You will receive a certificate post your donation on your e-mail address.</p>
            </div>
            <div style={{ minHeight: "500px", padding: "80px 20px" }} className={styles.carouselContainer}>
                <Carousel ref={carouselRef} dots={false} beforeChange={(_, current) => {
                    setCurrentCarouselPos(current);
                }}>
                    <div className={styles.carouselScreen1}>
                        <p className={styles.heading}>“ Brilliant idea. Using tech to encourage “giving” at weddings and marriage parties. Used it for my 50th birthday.”</p>
                        <div style={{ display: "flex", justifyContent: "center", height: "64px", width: "64px", border: "1px solid black", borderRadius: "50%", margin: "auto", overflow: "hidden" }}>
                            <img src={pic1}
                                style={{ height: "64px", width: "auto" }}
                            />
                        </div>

                        <p>Rajkumar Singhal</p>
                        <p>Ex MD Bank of America Merrill Lynch</p>
                    </div>
                    <div className={styles.carouselScreen1}>
                        <p className={styles.heading}>“ Used donacode for my daughter's birthday. All my guests were able to use in a few simple clicks. What a wonderful concept. ”</p>
                        <div style={{ display: "flex", justifyContent: "center", height: "64px", width: "64px", border: "1px solid black", borderRadius: "50%", margin: "auto", overflow: "hidden" }}>
                            <img src={pic2}
                                style={{ height: "64px", width: "auto" }}
                            />
                        </div>

                        <p>Mihir Patki</p>
                        <p>Founder Cvk advisors</p>
                    </div>
                </Carousel>
                <div className={styles.carouselDots}
                >
                    <button onClick={() => carouselGoto(0)} className={currentCarouselPos === 0 ? styles.activeCarousel : ''} />
                    <button onClick={() => carouselGoto(1)} className={currentCarouselPos === 1 ? styles.activeCarousel : ''} />
                    {/* <button onClick={() => carouselGoto(2)} className={currentCarouselPos === 2 ? styles.activeCarousel : ''} />
                    <button onClick={() => carouselGoto(3)} className={currentCarouselPos === 3 ? styles.activeCarousel : ''} /> */}
                </div>
                <span ></span>
            </div>
            <div id="faqs" className={styles.section4}>
                <div className={styles.gridItem1}>
                    <div>
                        <p className={styles.fontStyle3}>Here to help</p>
                        <p className={styles.fontStyle1} style={{ fontSize: "36px" }}>Frequently asked questions </p>
                    </div>

                </div>

                <div className={styles.gridItem2}>
                    <div className={styles.listItem}>
                        <Button type="link" className={styles.toggleBtn} onClick={() => setToggledIndices({ ...toggledIndices, '0': !toggledIndices['0'] })}>
                            <img src={circledplus} />
                        </Button>
                        <p className={styles.heading}>
                            How do I know that my money has reached the NGO?
                        </p>
                        <p className={classnames(styles.fontStyle4, styles.itemContent, {
                            [styles.content]: toggledIndices['0'],
                            [styles.contentHide]: !toggledIndices['0']
                        })}>
                            When the payment is successful, you will receive an email confirmation to your email address. It is
                            proof that the money has reached the NGO. Donacode takes care of it at the backend - the process is
                            completely automated.
                        </p>
                    </div>
                    <div className={styles.listItem}>
                        <Button type="link" className={styles.toggleBtn} onClick={() => setToggledIndices({ ...toggledIndices, '1': !toggledIndices['1'] })}>
                            <img src={circledplus} />
                        </Button>
                        <p className={styles.heading}>
                            How do I know that the NGO listed on Donacode will use the money properly?
                        </p>
                        <p className={classnames(styles.fontStyle4, styles.itemContent, {
                            [styles.content]: toggledIndices['1'],
                            [styles.contentHide]: !toggledIndices['1']
                        })}>
                            Only NGOs having accreditation with the Indian Government for purpose of tax deduction are listed
                            here. The NGOs have been further selected based on market presence and reputation.
                            While it is a future plan, please note that Donacode is unable to audit the end-usage of these funds
                            by the NGOs. That’s why there is a choice of NGOs. You can select the NGO you like.
                        </p>
                    </div>
                    <div className={styles.listItem}>
                        <Button type="link" className={styles.toggleBtn} onClick={() => setToggledIndices({ ...toggledIndices, '2': !toggledIndices['2'] })}>
                            <img src={circledplus} />
                        </Button>
                        <p className={styles.heading}>
                            How can I make payments?
                        </p>
                        <p className={classnames(styles.fontStyle4, styles.itemContent, {
                            [styles.content]: toggledIndices['2'],
                            [styles.contentHide]: !toggledIndices['2']
                        })}>
                            You can make payments via all the payment modes - debit card, credit card, UPI, etc. We are unable
                            to accept cash.


                            The NGO I want to support is not on the website. What should I do?
                            Write to muditbaid@donacode.com. I will get back to you if it can be included within 15 days.                        </p>
                    </div>
                    <div className={styles.listItem}>
                        <Button type="link" className={styles.toggleBtn} onClick={() => setToggledIndices({ ...toggledIndices, '3': !toggledIndices['3'] })}>
                            <img src={circledplus} />
                        </Button>
                        <p className={styles.heading}>
                            Does Donacode have any charges?
                        </p>
                        <p className={classnames(styles.fontStyle4, styles.itemContent, {
                            [styles.content]: toggledIndices['3'],
                            [styles.contentHide]: !toggledIndices['3']
                        })}>
                            Donacode does not take any money - we are a non-profit organization. There are payment gateway
                            charges of 3% to 5%. However, till December 2023, Donacode would be bearing that as well. It
                            means that whatever amount you donate, 100% of it will go towards the cause.
                        </p>
                    </div>
                    <div className={styles.listItem}>
                        <Button type="link" className={styles.toggleBtn} onClick={() => setToggledIndices({ ...toggledIndices, '4': !toggledIndices['4'] })}>
                            <img src={circledplus} />
                        </Button>
                        <p className={styles.heading}>
                            When will I receive my 80G certificate?
                        </p>
                        <p className={classnames(styles.fontStyle4, styles.itemContent, {
                            [styles.content]: toggledIndices['4'],
                            [styles.contentHide]: !toggledIndices['4']
                        })}>
                            Donacode takes care of the 80G certificates through an automated process. Once the payment is
                            successful, you will receive your 80G certificate at your email address within 24 hours.                        </p>
                    </div>
                    <div className={styles.listItem}>
                        <Button type="link" className={styles.toggleBtn} onClick={() => setToggledIndices({ ...toggledIndices, '5': !toggledIndices['5'] })}>
                            <img src={circledplus} />
                        </Button>
                        <p className={styles.heading}>
                            Can I create two Donacodes?
                        </p>
                        <p className={classnames(styles.fontStyle4, styles.itemContent, styles.itemContent, {
                            [styles.content]: toggledIndices['5'],
                            [styles.contentHide]: !toggledIndices['5']
                        })}>
                            Yes! Simply login in with another account on the website and you will be able to create another code
                            to share with your friends and family!
                        </p>
                    </div>
                    <div className={styles.listItem}>
                        <Button type="link" className={styles.toggleBtn} onClick={() => setToggledIndices({ ...toggledIndices, '6': !toggledIndices['6'] })}>
                            <img src={circledplus} />
                        </Button>
                        <p className={styles.heading}>
                            Can I change the NGOs I want to donate to?
                        </p>
                        <p className={classnames(styles.fontStyle4, styles.itemContent, styles.itemContent, {
                            [styles.content]: toggledIndices['6'],
                            [styles.contentHide]: !toggledIndices['6']
                        })}>
                            If you are creating a code for your friends and family, you can update the NGO names later. Users
                            making a contribution can select from the list of available options. They can change the NGO at
                            anytime before the payment is initiated.
                        </p>
                    </div>
                    <div className={styles.listItem}>
                        <Button type="link" className={styles.toggleBtn} onClick={() => setToggledIndices({ ...toggledIndices, '7': !toggledIndices['7'] })}>
                            <img src={circledplus} />
                        </Button>
                        <p className={styles.heading}>
                            How much minimum contribution can I make on Donacode?
                        </p>
                        <p className={classnames(styles.fontStyle4, styles.itemContent, styles.itemContent, {
                            [styles.content]: toggledIndices['7'],
                            [styles.contentHide]: !toggledIndices['7']
                        })}>
                            The minimum contribution is Rs 100.
                        </p>
                    </div>
                    <div className={styles.listItem}>
                        <Button type="link" className={styles.toggleBtn} onClick={() => setToggledIndices({ ...toggledIndices, '8': !toggledIndices['8'] })}>
                            <img src={circledplus} />
                        </Button>
                        <p className={styles.heading}>
                            My question is not listed here
                        </p>
                        <p className={classnames(styles.fontStyle4, styles.itemContent, styles.itemContent, {
                            [styles.content]: toggledIndices['8'],
                            [styles.contentHide]: !toggledIndices['8']
                        })}>
                            Mail your question to <a href="mailto:muditbaid@donacode.com">muditbaid@donacode.com</a> and we will get back to you within 7 days. Sorry,
                            the platform runs on tech and anything requiring human intervention takes more time.
                        </p>
                    </div>
                </div>

            </div>
            <div className={styles.section5}>
                <p className={styles.fontStyle1} style={{ fontSize: "30px", fontWeight: "700px" }}>A big change can start with a small gift.</p>
                <p className={styles.fontStyle2}>Start making a difference with how you receive gifts.</p>
                <div style={{ margin: "40px 0" }}>
                    <Button onClick={() => {
                        if (userDonacode)
                            return navigate(`/share-code/${userDonacode}`)
                        navigate("/create-code")
                    }} className={styles.section5Btn}>
                        <img src={gift} style={{ margin: "-4px 8px 0 0" }} />  {userDonacode ? "See your code" : "Make your own code"}
                    </Button>
                </div>
                <hr color="#EAECF0" />
                <div style={{ margin: "60px 0" }}>
                    <p className={styles.fontStyle4} style={{ textAlign: "left" }}>
                        On a mission to make the world a better <br />place for everyone.
                    </p>
                    <div className={styles.footerLinks} style={{ display: "flex", gap: "24px", fontSize: "16px", color: "#667085" }}>
                        <Link to="/about">About Us</Link>
                        <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
                        <Link to="#faqs">FAQs</Link>
                        <Link to="/privacy">Privacy</Link>
                    </div>
                </div>
                <hr color="#EAECF0" />
                <div>
                    <p style={{ textAlign: "left" }}>© 2022 DonaCode. All rights reserved.</p>
                </div>
            </div>
        </div>)
}

export default Home;