import { GoogleLogin } from '@react-oauth/google';
import RestClient from '../../apiClient/apiClient';
import { useAuth } from '../../hooks/useAuth';
import { openNotification } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function Google() {
    const {user, setUserToken} =useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        console.log(user)
        if (user) {
            openNotification(`Welcome back ${user?.name}`, "Welcome back, you are logged in!")
            navigate('/');
        }

    }, [user])

    return <>
        <GoogleLogin
            onSuccess={credentialResponse => {
                console.log(credentialResponse);
                RestClient.post("https://api.donacode.com/google", {id_token: credentialResponse.credential}).then((res)=>{
                    if(res.ok){
                        setUserToken(res.headers.get('Authorization').split(' ')[1])
                    }
                    else{
                        openNotification("Failure", "Something went wrong! Please try again.", "error")
                        navigate('/login')
                    }
                })
            }}
            onError={() => {
                openNotification("Failure", "Something went wrong! Please try again.", "error")
                console.log('Login Failed');
            }}
            useOneTap
        />
    </>
}

export default Google;