import { useState } from "react";

export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return typeof value === 'string' ? value :JSON.parse(value);
      } 
      if(defaultValue){
        window.localStorage.setItem(keyName, typeof defaultValue === 'string'? defaultValue : JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      console.log(err);
    }
  });
  const setValue = (newValue) => {
    try {
        if(newValue){
            window.localStorage.setItem(keyName, typeof newValue === 'string'? newValue : JSON.stringify(newValue));
            setStoredValue(newValue);  
        }
        else{
            window.localStorage.removeItem(keyName);
            setStoredValue();
        }
    } catch (err) {
        console.log(err);
    }
  };
  return [storedValue, setValue];
};