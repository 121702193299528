const retrieveAuthToken = () => localStorage.getItem('authToken');
 
/**
 * Service for making web api calls using es6 native `fetch`
 * @module class
 */
class RestClient {
  static defaultOptions = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  };
 
  // Inspired from http://api.jquery.com/jQuery.param/
  static encodeForUrl = (obj) => Object
    .keys(obj)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join('&');
 
  static buildAuthOptions() {
    return { 'Authorization': `Bearer ${retrieveAuthToken()}` };
  }
 
  /**
   * Send HTTP requests and returns the formatted response
   * response object looks like this
   * {
   *    ok: true,
   *    status: 200,
   *    statusText: 'OK',
   *    headers: {},
   *    result: {}
   * }
   * @param {String} url API endpoint
   * @param {Object} options
   * @return {Promise} Returns the response object containing the status and data
   */
  static async request(url, options) {
    const urlSplit = url.split('/');
    const isSignInApi = urlSplit[urlSplit.length-1] === 'sign_in' || (urlSplit[urlSplit.length-1] === 'users' && options.method === 'POST')
    const authOptions = this.buildAuthOptions();
    const allOptions = { ...this.defaultOptions, ...options };
    allOptions.headers = { ...this.defaultOptions.headers, ...options.headers };
    if(!isSignInApi)
        allOptions.headers = {
            ...allOptions.headers,
            ...authOptions
        };
    if (options.unsetContentType) {
      delete allOptions.headers['Content-Type'];
    }
    const response = await fetch(url, allOptions);
    const responseJson = (
      ({
        ok, status, statusText, headers,
      }) => ({
        ok, status, statusText, headers,
      })
    )(response);
    try {
      responseJson.result = await response.json();
    } catch (ex) {
      responseJson.ok = false;
      responseJson.result = null;
    }
    return responseJson;
  }
 
  /**
   * Send HTTP GET request
   * @param {String} url  API endpoint
   * @param {Object} payload
   * @return {Promise} Returns either the response in json, or throws an error
   */
  static get(url, payload = null) {
    const options = { method: 'GET' };
    let fullUrl = url;
    if (payload && Object.keys(payload).length !== 0) {
      fullUrl += `?${this.encodeForUrl(payload)}`;
    }
    return this.request(fullUrl, options);
  }
 
  /**
   * Send HTTP POST request
   * @param {String} url  API endpoint
   * @param {Object} payload
   * @return {Promise} Returns either the response in json, or throws an error
   */
  static post(url, payload) {
    
    const options = { method: 'POST', body: JSON.stringify(payload) };
    return this.request(url, options);
  }
 
  /**
   * Send HTTP PATCH request
   * @param {String} url API endpoint
   * @param {Object} payload
   * @return {Promise} Returns either the response in json, or throws an error
   */
  static patch(url, payload) {
    const options = { method: 'PATCH', body: JSON.stringify(payload) };
    return this.request(url, options);
  }

  static put(url, payload) {
    const options = { method: 'PUT', body: JSON.stringify(payload) };
    return this.request(url, options);
  }
 
  /**
   * Send HTTP DELETE request
   * @param {String} url  API endpoint
   * @param {Object} payload
   * @return {Promise} Returns either the response in json, or throws an error
   */
  static delete(url, payload = {}) {
    const options = { method: 'DELETE', body: JSON.stringify(payload) };
    return this.request(url, options);
  }
}

export default RestClient;