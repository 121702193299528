import React from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages/home/home';
import PersonalInfo from './pages/personalInfo/personalInfo';
import './App.css';
import PaymentDetails from './pages/paymentDetails/paymentDetails';
import Signup from './pages/signup/signup';
import ShareCode from './pages/shareCode/shareCode';
import Login from './pages/login/login';
import { ProtectedRoute } from './utils';
import { AuthProvider } from './hooks/useAuth';
import SuccessPayment from './pages/successPayment/successPayment';
import { GoogleOAuthProvider } from '@react-oauth/google';
import About from './pages/about/about';
import TNC from './pages/terms/terms';
import Privacy from './pages/privacy/privacy';


function App() {
  return (
    <div className='App'>
      <GoogleOAuthProvider clientId="983347030609-lu1jhrunf3o47mn8i2nof2h8c0ijc155.apps.googleusercontent.com">
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/login" element={<Login />} />
            <Route path='/signup' element={<Signup />} />
            <Route
              path="/share-code/:donacode"
              element={
                <ProtectedRoute>
                  <ShareCode />
                </ProtectedRoute>
              }
            />
            <Route
              path="/create-code"
              element={
                <ProtectedRoute>
                  <PersonalInfo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/payment_gateway"
              element={
                <SuccessPayment />
              }
            />
            <Route
              path=":donacode"
              element={
                <PaymentDetails />
              }
            />
            <Route
              path="terms-and-conditions"
              element={
                <TNC />
              }
            />
            <Route
              path="privacy"
              element={
                <Privacy />
              }
            />
          </Routes>
        </AuthProvider>
      </GoogleOAuthProvider>;
    </div>
  );
}

export default App;
