import React, { useState, useEffect } from "react";
import { Button, Tag } from "antd";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import FormItem from "../../components/formItem/formItem";
import SelectItem from "../../components/selectItem/selectItem";
import styles from './style.module.scss';
import CollapsibleRow from "../../components/collapsibleRow/collapsibleRow";
import { ReactComponent as RightMarkIcon } from "../../assets/rightIcon.svg";
import { ReactComponent as NgoIcon } from "../../assets/ngo1.svg";
import { ReactComponent as MagnifyIcon } from "../../assets/magnifyIcon.svg";
import { ReactComponent as CrossIcon } from "../../assets/cross.svg";
import { ReactComponent as FireweokIcon } from '../../assets/fireworkWhite.svg';
import { ReactComponent as DesignIcon } from '../../assets/designicon.svg';
import { useAuth } from "../../hooks/useAuth";
import RestClient from "../../apiClient/apiClient";


// const ngoList = [
//     {
//         icon: <NgoIcon />,
//         title: "NGO #1",
//         description: "Helps in children’s education. Helps in children’s education. Helps in children’s education.",
//         isSelected: true,
//     }, 
//     {
//         icon: <NgoIcon />,
//         title: "NGO #2",
//         description: "Helps in children’s education",
//         isSelected: true,
//     },
//     {
//         icon: <NgoIcon />,
//         title: "NGO #3",
//         description: "Helps in children’s education",
//         isSelected: true,
//     },
//     {
//         icon: <NgoIcon />,
//         title: "NGO #2",
//         description: "Helps in children’s education",
//         isSelected: true,
//     },
//     {
//         icon: <NgoIcon />,
//         title: "NGO #3",
//         description: "Helps in children’s education",
//         isSelected: true,
//     }
// ]

// const selectedTagsInfo = [
//     "NGO #1",
//     "NGO #2"
// ]

const PersonalInfo = ({ title }) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [searchValue, setSearchValue] = useState("")
    const [phoneErr, setPhoneErr] = useState(false);
    const [personalInfoCompleted, setPersonalInfoCompleted] = useState(false);
    const [ngoList, setNgoList] = useState({});
    const [selectedNgos, setSelectedNgos] = useState([]);
    const [completedNgoForm, setCompletedNgoForm] = useState(false);
    const [selectedTagsInfo, setSelectedTagsInfo] = useState([]);
    const { user } = useAuth();
    const navigate = useNavigate();

    const handleSubmitPersonalInfo = () => {
        const validatePhone = (/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/).test(phone);
        console.log((/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/).test(phone));
        if (name && email && phone && validatePhone) {
            setPersonalInfoCompleted(true);
        }
        else {
            setPhoneErr(true);
        }
    }

    const handleSubmitNgoForm = () => {
        if (selectedNgos.length >= 1) {
            setCompletedNgoForm(true)
        }
    }

    const handleSelectNGO = (key) => {
        console.log('yo', selectedNgos.includes(key))
        if (selectedNgos.includes(key))
            setSelectedNgos(selectedNgos.filter((ngo) => ngo !== key));
        else {
            setSelectedNgos([...selectedNgos, key]);
        }
    }

    const createDonacode = () => {
        RestClient.put('https://api.donacode.com/api/v1/users', {
            user: {
                name,
                phone_number: phone,
                ngo_ids: selectedNgos
            }
        }).then((response) => {
            const url = `/share-code/${response.result.data.user.donacode ?? "notavail"}`;
            console.log({ url })
            navigate(url);
        }).catch((err) => {
            console.log("error", err);
        })
    }

    useEffect(() => {
        if (user) {
            setName(user.name ?? "Alex");
            setEmail(user.email);
            setPhone(user.phone_number);
        }
    }, [user]);

    useEffect(() => {
        if (personalInfoCompleted) {
            RestClient.get('https://api.donacode.com/api/v1/ngos').then((response) => {
                const ngoGroupCollection = {}
                console.log(response)
                response.result.forEach((ngo) => {
                    if (!ngoGroupCollection[ngo.category])
                        ngoGroupCollection[ngo.category] = [];

                    ngoGroupCollection[ngo.category].push(ngo);
                })

                setNgoList(ngoGroupCollection);
            })
        }
    }, [personalInfoCompleted]);

    return (
        <div>
            <Navbar />
            <div className={styles.personalInfoSection}>
                <p className={styles.fontStyle3}>Make your code</p>
                <p className={classnames(styles.fontStyle1, styles.heading)}>Let's get started <DesignIcon className={styles.designicon} /></p>
                <CollapsibleRow
                    title="1. Personal Information"
                    about="Just some basic information to get to know you better."
                    openCollapseValue={!personalInfoCompleted}
                    editBtnEnable={personalInfoCompleted}
                    showCompleted={personalInfoCompleted}
                    onEditClick={() => setPersonalInfoCompleted(false)}
                    footer={<Button className={styles.formButton} onClick={handleSubmitPersonalInfo}><RightMarkIcon style={{ marginRight: "8px" }} /> My details are accurate</Button>}
                >
                    <div className={styles.inlineForm}>
                        <FormItem name="username" label={"Name"} value={name} disabled onChange={(e) => setName(e.target.value)} required />
                        <FormItem name="email" label={"Email address"} value={email} disabled onChange={(e) => setEmail(e.target.value)} required/>
                        <FormItem name="phone" label={"Your contact number"} value={phone} validatePhoneStatus={phoneErr ? "error" : null} onChange={(e) => { setPhone(e.target.value); setPhoneErr(false); }} required/>
                    </div>
                </CollapsibleRow>
                <CollapsibleRow
                    title="2. Pick the NGOs you’d like to support"
                    about="Pick up to 10 NGOs you’d want your friends & family to donate to."
                    openCollapseValue={!completedNgoForm && personalInfoCompleted}
                    editBtnEnable={completedNgoForm}
                    showCompleted={completedNgoForm}
                    onEditClick={() => setCompletedNgoForm(false)}
                    disabled={!personalInfoCompleted}
                    footer={
                        <Button className={styles.formButton} onClick={handleSubmitNgoForm} disabled={selectedNgos.length === 0}>
                            <RightMarkIcon style={{ marginRight: "8px" }} />
                            {selectedNgos.length ? `Confirm these ${selectedNgos.length} NGOs` : "No NGO selected"}
                        </Button>
                    }
                >
                    <FormItem
                        name="searchBox"
                        placeholder="You can search by typing either a cause or the name of an NGO. Eg. Child education, XYZ Org"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        style={{ marginBottom: "16px" }}
                        extraInputProps={{
                            prefix: <MagnifyIcon style={{ height: "15px", width: "15px" }} />,
                            onPressEnter: () => {
                                setSelectedTagsInfo([...selectedTagsInfo, searchValue]);
                                setSearchValue("");
                            }
                        }}
                    />
                    <div className={styles.selectedTagsContainer}>
                        {selectedTagsInfo.map((tagInfo, index) => (
                            <Tag className={styles.selectedSearchTag} color="error">
                                {tagInfo} <CrossIcon onClick={() => setSelectedTagsInfo(selectedTagsInfo.filter((_, ind) => ind!=index))} />
                            </Tag>
                        ))}
                    </div>
                    <hr style={{ margin: "20px -40px 0px -40px", borderBottom: "0px" }} color="#D0D5DD" />
                    <div className={styles.multiSelectContainer}>
                        {Object.keys(ngoList).map((group) =>
                            <div className={styles.multiSelectSection}>
                                <p className={styles.heading}>{group}</p>
                                <SelectItem items={ngoList[group]} selectedEntries={selectedNgos} includedIn={selectedTagsInfo} onSelect={handleSelectNGO} />
                            </div>
                        )}
                    </div>

                </CollapsibleRow>
                <div style={{ display: "flex", justifyContent: "center", margin: "40px 0", opacity: completedNgoForm ? 1 : 0.4 }}>
                    <Button onClick={createDonacode} className={styles.section5Btn}>
                        <FireweokIcon className={styles.icon} disabled={!completedNgoForm} />  Make my Donacode
                    </Button>
                </div>
            </div>
            <div className={styles.pageFooter}>
                <span className={styles.note}>© 2022 DonaCode.<br /> All rights reserved.</span>
                <a href="/about">About Us</a>
                <a href="/terms-and-conditions">Terms &amp; Conditions</a>
                <a href="/#faqs">FAQs</a>
                <a href="/privacy">Privacy</a>
            </div>
        </div>
    )
}

export default PersonalInfo;