import React, { useState, useEffect } from "react";
import { redirect, useSearchParams } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import styles from './style.module.scss';
import { ReactComponent as RighMarkIcon } from "../../assets/rightmarkcircle.svg";
import RestClient from "../../apiClient/apiClient";

const SuccessPayment = () => {

    const [searchParams] = useSearchParams();
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [tranactionId, setTranactionId] = useState();
    const [amountDonated, setAmountDonated] = useState();
    const [senderUser, setSenderUser]  = useState();

    const loadDanamojo = (callback) => {
        const existingScript = document.getElementById('danamojo');
        if (!existingScript) {
          const script = document.createElement('script');
          script.src = 'https://danamojo.org/dm/js/widget.js';
          script.id = 'danamojo';
          document.body.appendChild(script);
          script.onload = () => { 
            if (callback) callback();
          };
        }
        if (existingScript && callback) callback();
      };

    useEffect(() =>{
        if(!searchParams.get("donationInfoId") || !searchParams.get("amount"))
        loadDanamojo(async () => {
            setScriptLoaded(true);
        });
        
    }, [searchParams, amountDonated]);

    useEffect(() => {
        let timedFn;
        if(!tranactionId || !searchParams.get("amount"))
        timedFn = setInterval(() => {
            if(scriptLoaded && document.getElementById('danamojo') && (!searchParams.get("donationInfoId"))){

                document.getElementById("NGObtn").setAttribute("iNGOId", searchParams.get('ngo_id'));
                document.getElementById("NGObtn").click();
                setScriptLoaded(false);
            }

        }, 2000);

        return () => {
            if(timedFn)
            clearInterval(timedFn);

        }
        
    }, [scriptLoaded, searchParams, tranactionId]);


    useEffect(() => {
        let timedFn;
        if(scriptLoaded && searchParams.get("donationInfoId") && !searchParams.get("amount"))
        timedFn = setInterval(() => {
            const domElem = document.getElementById('dmTotalDonationAmount');
            if(domElem){
                window.location = `/payment_gateway?${searchParams}&amount=${domElem.innerHTML}`;
            }

        }, 1000);

        return () => {
            if(timedFn)
            clearInterval(timedFn);

        }
        
    }, [scriptLoaded, searchParams, amountDonated]);

    useEffect(() => {
        if(searchParams.get("donationInfoId")){
            setTranactionId(searchParams.get("donationInfoId"))
        }
        if(searchParams.get("senderUser")){
            setSenderUser(searchParams.get("senderUser"))
        }
        if(searchParams.get("amount")){
            setAmountDonated(searchParams.get("amount"));

            RestClient.put(`https://api.donacode.com/api/v1/orders/${searchParams.get("utm_campaign")}`, {
                order: {
                    amount: searchParams.get("amount"),
                    reference_id: searchParams.get("donationInfoId")
                }
            })
        }
    }, [searchParams]);

    return (
        <div className={styles.successPaymentPage}>
            <Navbar />
            {tranactionId && amountDonated ? 
            <div style={{ flexGrow: 1}} >
                <div className={styles.successPaymentSection} style={{ textAlign: "center"}}>
                    <div>
                        <RighMarkIcon />
                    </div>
                    <p className={styles.fontStyle1}>Your gift has been sent.</p>
                    
                    <div className={styles.donacodeCard}>
                        <p style={{color: "#E31B54", fontSize: "20px", fontWeight:"700"}}>You’ve successfully donated INR {amountDonated}, Reference id: {tranactionId}</p>
                        <p className={styles.fontStyle4} style={{fontSize: "20px"}}>
                            You will receive an email confirmation and details for your donation.
                            <br/>{senderUser} will receive an acknowledgement for your gift soon. 
                        </p>
                    </div>
                </div>
            </div>:
            <div className={styles.fontStyle1} style={{ flexGrow: 1, fontSize: "80px", margin: "auto 100px"}}>
                Please wait!
            </div>}
            <div className={styles.pageFooter}>
                <span className={styles.note}>© 2022 DonaCode.<br /> All rights reserved.</span>
                <a href="/about">About Us</a>
                <a href="/terms-and-conditions">Terms &amp; Conditions</a>
                <a href="/#faqs">FAQs</a>
                <a href="/privacy">Privacy</a>
            </div>
        </div>
    )
}

export default SuccessPayment;