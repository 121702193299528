import React, { useEffect, useState } from "react";
import { Tag } from "antd";
import styles from "./style.module.scss"
import { ReactComponent as EditIcon } from "../../assets/editIcon.svg"
import { ReactComponent as DotIcon} from "../../assets/dotIcon.svg" 

const CollapsibleRow = ({ title, about, children, openCollapseValue, editBtnEnable, showCompleted=true, onEditClick, disabled, footer }) => {
    const [openCollapse, setOpenCollapse] = useState(true);

    useEffect(() => {
        setOpenCollapse(openCollapseValue)
    }, [openCollapseValue])

    return (
        <div className={styles.toggleRow} style={{ opacity: disabled? 0.5 : 1}}>
            <div className={styles.head}>
                {!openCollapse && editBtnEnable && 
                    <button 
                        className={styles.editBtn} 
                        onClick={() => {
                            setOpenCollapse(true);
                            if(onEditClick)
                                onEditClick();
                        }}
                    >
                        <EditIcon /> Edit
                    </button>
                }
                <p style={{  display: "flex", height: "auto", fontSize: "18px", fontWeight: "500", marginBottom: "12px" }}>
                    {title} 
                    {showCompleted && 
                        <span>
                            <Tag className={styles.completeTag} color="success"> <DotIcon  className={styles.dotIcon}/> Completed</Tag> 
                        </span>}
                </p>
                <p className={styles.fontStyle4} style={{ fontSize: "16px" }}>{about}</p>
            </div>
            {openCollapse ?
                <>
                    <div className={styles.collapsible}>
                        {children}
                    </div>
                    <hr color="#EAECF0" />
                    <div className={styles.footer}>
                        {footer}
                    </div>
                </>
                : null
            }
        </div>
    )
}

export default CollapsibleRow;