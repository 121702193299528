import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Skeleton, Tag } from "antd";
import classnames from "classnames";
import RestClient from "../../apiClient/apiClient";
import Navbar from "../../components/navbar/navbar";
import FormItem from "../../components/formItem/formItem";
import SelectItem from "../../components/selectItem/selectItem";
import styles from './style.module.scss';
import CollapsibleRow from "../../components/collapsibleRow/collapsibleRow";
import { openNotification } from "../../utils";
import { ReactComponent as RightMarkIcon } from "../../assets/rightIcon.svg";
import { ReactComponent as MagnifyIcon } from "../../assets/magnifyIcon.svg";
import { ReactComponent as CrossIcon } from "../../assets/cross.svg";
import { ReactComponent as FireweokIcon } from '../../assets/fireworkWhite.svg';
import { ReactComponent as HeartIcon } from '../../assets/hearticon.svg';


const PaymentDetails = ({ }) => {



    const [pageLoading, setPageLoading] = useState(true);
    const [senderUser, setSenderUser] = useState();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [panInfo, setPanInfo] = useState("");
    const [phoneErr, setPhoneErr] = useState(false);
    const [personalInfoCompleted, setPersonalInfoCompleted] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [ngoList, setNgoList] = useState([]);
    const [selectedNgo, setSelectedNgo] = useState([]);
    const [externalId, setExternalId] = useState();
    const [data, setData] = useState([]);
    const [completedNgoForm, setCompletedNgoForm] = useState(false);
    const [selectedTagsInfo, setSelectedTagsInfo] = useState([]);
    const [paymentCompleted, setPaymentCompleted] = useState(false);
    const [submittedNote, setSubmittedNote] = useState("");
    const [note, setNote] = useState("");
    const [fetchedCall, setFetchedCall] = useState(false);

    const navigate = useNavigate();
    const { donacode } = useParams();

    const handleSubmitPersonalInfo = () => {
        const validatePhone = (/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/).test(phone);

        if (name && email && phone && validatePhone && panInfo) {
            setPersonalInfoCompleted(true);
        }
        else {
            setPhoneErr(true);
        }
    }

    const handleSubmitNgoForm = () => {
        if (selectedNgo) {
            setCompletedNgoForm(true)
        }
    }

    const handleSelectNGO = (key) => {
        if (!(selectedNgo === key)) {
            setSelectedNgo(key);
            setExternalId(data.find(o => o.id === key).external_id);
        } else {
            setSelectedNgo();
        }
    }

    const handleRemoveTag = (removeIndex) => {
        const updatedTags = [];

        selectedTagsInfo.forEach((tag, index) => {
            if (index !== removeIndex)
                updatedTags.push(tag);
        })

        setSelectedTagsInfo(updatedTags);
    }

    const handlePay = () => {
        RestClient.post("https://api.donacode.com/api/v1/orders", {
            order: {
                name,
                email,
                phone_number: phone,
                pan_number: panInfo,
                note,
                donacode,
                ngo_id: selectedNgo
            }
        }).then((res) => {
            // need to change to external id
            navigate(`/payment_gateway?senderUser=${senderUser}&ngo_id=${externalId}&utm_campaign=${res.result.data.order.id}`)
        })
    }

    useEffect(() => {
        if (!senderUser && !fetchedCall) {
            RestClient.get(`https://api.donacode.com/api/v1/users/donacode?donacode=${donacode}`).then((res) => {
                setFetchedCall(true);
                if (res.ok) {
                    setSenderUser(res?.result?.data?.user);
                    const ngoGroupCollection = {};
                    setData(res?.result?.data?.ngos);
                    res.result.data.ngos.forEach((ngo) => {
                        if (!ngoGroupCollection[ngo.category])
                            ngoGroupCollection[ngo.category] = [];

                        ngoGroupCollection[ngo.category].push({ ...ngo });
                    })

                    setNgoList(ngoGroupCollection);

                }
                else {
                    openNotification("Failure", "The code seems to be incorrect. Please try again later.", "error")
                    navigate("/");
                }

            }).catch((err) => {
                console.log("Error ", err);
            })
                .finally(() => {
                    setPageLoading(false)
                })
        }

    }, [donacode, senderUser, fetchedCall]);

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Navbar />
            <div className={styles.codeInfoBar}>
                <HeartIcon style={{ height: "48px", width: "48px" }} />
                <span style={{ padding: "12px 0" }}>You're donating via {senderUser?.name}&apos;s code: <b>{donacode}</b></span>
            </div>

            <div className={styles.payemntDetailsSection}>
                <p className={styles.fontStyle3}>Donating to a good cause</p>
                <p className={classnames(styles.fontStyle1, styles.heading)}>{senderUser?.name}&apos;s gift of choice</p>
                <CollapsibleRow
                    title="1. Personal Information"
                    about="Just some basic information to get to know you better."
                    openCollapseValue={!personalInfoCompleted}
                    editBtnEnable={personalInfoCompleted}
                    showCompleted={personalInfoCompleted}
                    onEditClick={() => setPersonalInfoCompleted(false)}
                    footer={<Button className={styles.formButton} onClick={handleSubmitPersonalInfo}><RightMarkIcon style={{ marginRight: "8px" }} /> My details are accurate</Button>}
                >
                    <div className={styles.inlineForm}>

                        <FormItem name="username" label={"Name"} value={name} onChange={(e) => setName(e.target.value)}  required={true}/>
                        <FormItem name="email" label={"Email address"} value={email} onChange={(e) => setEmail(e.target.value)} required/>
                        <FormItem name="phone" label={"Your contact number"} value={phone} onChange={(e) => setPhone(e.target.value)} error={phoneErr} required/>
                        <FormItem name="pan" label={"Your PAN Card Number"} value={panInfo} onChange={(e) => setPanInfo(e.target.value)} required/>
                    </div>
                </CollapsibleRow>
                <CollapsibleRow
                    title="2. Pick the NGOs you’d like to support"
                    about="Pick up to 10 NGOs you’d want your friends & family to donate to."
                    openCollapseValue={!completedNgoForm && personalInfoCompleted}
                    editBtnEnable={completedNgoForm}
                    showCompleted={completedNgoForm}
                    onEditClick={() => setCompletedNgoForm(false)}
                    disabled={!personalInfoCompleted}
                    footer={
                        <Button className={styles.formButton} onClick={handleSubmitNgoForm} disabled={!selectedNgo}>
                            <RightMarkIcon style={{ marginRight: "8px" }} />
                            {selectedNgo ? `Confirm this NGO` : "No NGO selected"}
                        </Button>
                    }
                >
                    <FormItem
                        name="searchBox"
                        placeholder="You can search by typing either a cause or the name of an NGO. Eg. Child education, XYZ Org"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        style={{ marginBottom: "16px" }}
                        extraInputProps={{
                            prefix: <MagnifyIcon style={{ height: "15px", width: "15px" }} />,
                            onPressEnter: () => {
                                setSelectedTagsInfo([...selectedTagsInfo, searchValue]);
                                setSearchValue("");
                            }
                        }}
                    />
                    <div className={styles.selectedTagsContainer}>
                        {selectedTagsInfo.map((tagInfo, index) => (
                            <Tag className={styles.selectedSearchTag} color="error">
                                {tagInfo} <CrossIcon onClick={() => handleRemoveTag(index)} />
                            </Tag>
                        ))}
                    </div>
                    <hr style={{ margin: "20px -40px 0px -40px", borderBottom: "0px" }} color="#D0D5DD" />
                    <div className={styles.multiSelectContainer}>
                        {Object.keys(ngoList).map((group) =>
                            <div className={styles.multiSelectSection}>
                                <p className={styles.heading}>{group}</p>
                                <SelectItem items={ngoList[group]} selectedEntries={[selectedNgo]} includedIn={selectedTagsInfo} onSelect={handleSelectNGO} />
                            </div>
                        )}
                    </div>

                </CollapsibleRow>
                <CollapsibleRow
                    title={`3. Add a note for ${senderUser?.name}`}
                    about="It's a gift, after all. Spread the cheer :)"
                    openCollapseValue={completedNgoForm}
                    showCompleted={note}
                    disabled={!completedNgoForm}
                    footer={<Button className={styles.formButton} onClick={() => setNote(submittedNote)}><RightMarkIcon style={{ marginRight: "8px" }} /> I&apos;m happy with my note</Button>}
                >


                    <FormItem
                        name="note"
                        value={submittedNote}
                        placeholder="Type a message for Raj, they’ll receive it once you’ve donated any amount to an NGO of their choice."
                        onChange={(e) => setSubmittedNote(e.target.value)}
                    />
                </CollapsibleRow>
                {/* <CollapsibleRow
                    title="4. How much would you like to donate?"
                    about={<span>All your donations are exempt from taxation under section 80G <a href="#" style={{ textDecoration: "none", color: "#98A2B3" }}>Learn More</a></span>}
                    editBtnEnable
                    footer={
                        <div className={styles.donateFooterArea}>
                            <span className={styles.icon}><InfoIcon /></span>
                            <div className={styles.msgArea}>
                                <p style={{ fontSize: "16px", fontWeight: "400" }}>Please note:</p>
                                <p style={{ fontSize: "14px", fontWeight: "400", color: "#667085"}}>
                                    DonaCode is a non-profit organisation and does not accept any payments directly.<br/>
                                    We will be redirecting you to the NGO’s website so you can donate to them directly.
                                    This is to provide transparency in the transaction and remove the doubt of any hidden charges.
                                </p>
                            </div>
                            <div style={{ margin: "0 30px" }}>
                                <Button onClick={() => { console.log('click1') }} className={styles.section5Btn}>
                                    <GuardIcon className={styles.icon} style={{ height: "16px", top: "15px", left: "12px"}}/>  Understood, let's proceed
                                </Button>
                            </div>
                        </div>
                    }
                >
                    <div style={{ width: "70%" }}>
                        <FormItem
                            name="note"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            extraInputProps={{
                                prefix: "₹"
                            }}
                        />
                    </div>
                </CollapsibleRow> */}
                <div style={{ display: "flex", justifyContent: "center", margin: "40px 0", opacity: completedNgoForm ? 1 : 0.4 }}>
                    <Button onClick={handlePay} className={styles.section5Btn} disabled={!completedNgoForm}>
                        <FireweokIcon className={styles.icon} />  Proceed to payment
                    </Button>
                </div>
            </div >
            <div className={styles.pageFooter}>
                <span className={styles.note}>© 2022 DonaCode.<br /> All rights reserved.</span>
                <a href="/about">About Us</a>
                <a href="/terms-and-conditions">Terms &amp; Conditions</a>
                <a href="/#faqs">FAQs</a>
                <a href="/privacy">Privacy</a>
            </div>
        </div >
    )
}

export default PaymentDetails;