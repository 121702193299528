import React, { useEffect, useRef, useState } from "react";
import { Carousel, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import RestClient from "../../apiClient/apiClient";
import FormItem from "../../components/formItem/formItem";
import { ReactComponent as GoogleIcon } from '../../assets/g_signup.svg';
import styles from "./style.module.scss";
import logo from "../../assets/Donacode_white.svg";
import logoDark from "../../assets/Donacode.svg";
import { useAuth } from "../../hooks/useAuth";
import { openNotification } from "../../utils";
import pic1 from "../../assets/raj_kumar.jpeg";
import pic2 from "../../assets/mihir.jpeg";
import Google from "../signup/google";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [currentCarouselPos, setCurrentCarouselPos] = useState(0);
    const { user, setUserToken } = useAuth();
    const carouselRef = useRef();
    const navigate = useNavigate();

    const carouselGoto = (screenIndex) => {
        carouselRef.current.goTo(screenIndex, false);
    }

    useEffect(() => {
        console.log(user)
        if (user) {
            openNotification(`Welcome back ${user?.name}`, "Welcome back, you are logged in!")
            navigate('/');
        }

    }, [user])

    const handleClickLogin = () => {
        console.log({ RestClient })
        RestClient.post('https://api.donacode.com/users/sign_in', { "user": { "email": email, "password": password } })
            .then((response) => {
                if (!response.ok) {
                    openNotification("Failure", "Something went wrong! Please check your credentials and try again.", "error")
                }
                else {
                    setUserToken(response.headers.get('Authorization').split(' ')[1])
                }
            })
    }

    return (
        <div className={styles.signup}>
            <nav className={styles.navbar}>
                <div className={styles.title} style={{ cursor: "pointer" }} onClick={() => navigate('/')}><img className={styles.logoLight} src={logo} /> <img className={styles.logoDark} src={logoDark} /></div>
            </nav>
            <div className={styles.panes}>
                <div className={styles.firstPane} style={{ background: "#89123E" }}>
                    <div className={styles.carouselContainer}>
                        <Carousel ref={carouselRef} dots={false} beforeChange={(_, current) => {
                            console.log(current);
                            setCurrentCarouselPos(current);
                        }}>
                            <div className={styles.carouselScreen}>
                                <p className={styles.heading}>“ Brilliant idea. Using tech to encourage “giving” at weddings and marriage parties. Used it for my 50th birthday.”</p>
                                <div style={{ display: "flex", justifyContent: "center", height: "64px", width: "64px", border: "1px solid black", borderRadius: "50%", margin: "auto", overflow: "hidden" }}>
                                    <img src={pic1}
                                        style={{ height: "64px", width: "auto" }}
                                    />
                                </div>

                                <p>Rajkumar Singhal</p>
                                <p>Ex MD Bank of America Merrill Lynch</p>
                            </div>
                            <div className={styles.carouselScreen}>
                                <p className={styles.heading}>“ Used donacode for my daughter s birthday. All my guests were able to use in a few simple clicks. What a wonderful concept. ”</p>
                                <div style={{ display: "flex", justifyContent: "center", height: "64px", width: "64px", border: "1px solid black", borderRadius: "50%", margin: "auto", overflow: "hidden" }}>
                                    <img src={pic2}
                                        style={{ height: "64px", width: "auto" }}
                                    />
                                </div>

                                <p>Mihir Patki</p>
                                <p>Founder Cvk advisors</p>
                            </div>
                        </Carousel>
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <Button type="link" className={styles.carouselControl} onClick={() => carouselGoto((currentCarouselPos - 1) < 0 ? 1 : (currentCarouselPos - 1))}>&lt;</Button>
                            <div className={styles.carouselDots}>
                                <button onClick={() => carouselGoto(0)} className={currentCarouselPos === 0 ? styles.activeCarousel : ''} />
                                <button onClick={() => carouselGoto(1)} className={currentCarouselPos === 1 ? styles.activeCarousel : ''} />
                                {/* <button onClick={() => carouselGoto(2)} className={currentCarouselPos === 2 ? styles.activeCarousel : ''} />
                                <button onClick={() => carouselGoto(3)} className={currentCarouselPos === 3 ? styles.activeCarousel : ''} /> */}
                            </div>
                            <Button type="link" className={styles.carouselControl} onClick={() => carouselGoto((currentCarouselPos + 1) % 2)}>&gt;</Button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.form}>
                        <p className={styles.fontStyle1} style={{ fontSize: "36px", margin: "0" }}>Login</p>
                        <FormItem name="email" label={"Email*"} placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} labelStyle={{ paddingBottom: '6px' }} />
                        <div>
                            <FormItem name="password" label={"Password*"} type="password" placeholder="Create a password" value={password} onChange={(e) => setPassword(e.target.value)} labelStyle={{ paddingBottom: '6px' }} />
                            <span style={{ color: "#667085" }}>Must be at least 8 characters.</span>
                        </div>
                        <Button onClick={() => { console.log("hello"); handleClickLogin(); }} className={styles.section5Btn}>
                            Login
                        </Button>
                        {/* <Button onClick={() => { console.log('click1') }} className={styles.googleSigninBtn}>
                            <GoogleIcon className={styles.googleIcon} /> Sign in with Google
                        </Button> */}
                        <Google />
                        <p>Don't have an account? <Link to={`/signup`}>Create an account</Link></p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Login;