import React from "react";
import { Button } from "antd";
import styles from "./style.module.scss";
import { ReactComponent as SelectedIcon } from "../../assets/selected.svg";
import { ReactComponent as NgoIcon } from "../../assets/ngo1.svg";

export const ListItem = ({ id, name, description, isSelected, onSelect }) => {
    return (
        <div className={styles.listItem} onClick={() => onSelect(id)}>
            <div className={styles.icon}>
                <NgoIcon />
            </div>
            <div className={styles.content}>
                <p className={styles.title}> {name} </p>
                <p className={styles.description}> {description} </p>
            </div>
            <div className={styles.selection}>
                {isSelected && <SelectedIcon />}
            </div>
        </div>
    )
};

const SelectItem = ({items, includedIn, onSelect, selectedEntries}) => {
    return (
        <div className={styles.itemsContainer}>
            {items.filter((item) => 
                includedIn.length === 0 || includedIn.reduce((prevVal, val) =>{ 
                    return prevVal || (item.name.toLowerCase().includes(val.toLowerCase()))}, false)
            ).map((itemProps) => (
                <ListItem 
                    {...itemProps}
                    isSelected={selectedEntries.includes(itemProps.id)}
                    key={itemProps.id}
                    onSelect={onSelect}
                />
            ))}
        </div>
    )
}

export default SelectItem;