import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { notification } from 'antd';
import { useEffect } from "react";

export const ProtectedRoute = ({ children }) => {
  const { userToken } = useAuth();

  if (!userToken) {
    // user is not authenticated
    openNotification("Not Authorised", "Please login before proceeding further.", "info")
    return <Navigate to="/login" />;
  }
  return children;
};

export const openNotification = (title, description, type='info') => {
  notification[type]({
    message: title,
    description: description,
    onClick: () => {
      console.log('Notification Clicked!');
    },
  });
};
